<template>
    <div class="lasttrades">
        <div class="icon">
            <img src="../../assets/icons/time-is-money1.svg" alt="">
            <span> آخرین معاملات بازار ها </span>
        </div>
        <div class="hooper-container">
                <Hooper :settings="hoopersSetting">
                        <Slide v-for="(item,index) in coins" :key="index" class="trade-info">

                            <div class="row">
                                <div class="coin-label">
                                    <div class="changes">
                                        <span :class="item.last24HourChange > 0 ? 'success': 'fail'"> {{item.last24HourChange > 0 ? $toLocal(item.last24HourChange,2) + '+' :   $toLocal(Math.abs(item.last24HourChange)) + '-' }} </span>
                                        <inline-svg :class="item.last24HourChange > 0 ? 'success': 'fail'" :src="require('../../assets/icons/changes.svg')" alt="" />
                                    </div>
                                    <img :src="'/coins/'+ $coin2Snake[item.relatedCoin] +'.png'" alt="">
                                </div>
                                <span class="coin-label">
                                    {{$coinLabel[item.relatedCoin]}}
                                </span>
                                <span class="coin-label">
                                    {{$coinUnit[item.relatedCoin]}}
                                </span>
                            </div>
                            <div class="row">
                                <span class="coin-label">
                                    <img :src="'/coins/TOMAN.png'" alt="">
                                    تومان
                                </span>
                                <span class="coin-label">
                                    {{$toLocal(item.lastPrice,0)}}
                                </span>
                            </div>


                                    
                        </Slide>
                </Hooper>
            </div>
    </div>
</template>
<script>
import {Hooper, Slide} from 'hooper'
import 'hooper/dist/hooper.css';
export default {
    name:'LastTrades',
    components: {
      Hooper,
      Slide,
    //   HooperNavigation
    },
    data() {
      return {
        hoopersSetting:{

        rtl:true,
        infiniteScroll:true,
        autoPlay:true,
        autoplaySpeed: 4000,
        itemsToShow: 3,
        centerMode:true,
        breakpoints : {
        2200:{
            itemsToShow: 10,
            centerMode:true,
        },
        2000:{
            itemsToShow: 8,
            centerMode:true,
        },
        1700:{
            itemsToShow: 6,
            centerMode:true,
        },
        1200:{
            itemsToShow: 4,
            centerMode:true,
        },
          800:{
            itemsToShow: 3,
            centerMode:true,
          },
          500:{
            itemsToShow:2,

          },
          320:{
            itemsToShow:1,
            centerMode:true,
          }
        }
      },
      }
    },
    props:['allCoins'],
    computed:{
            coins() {
                let coin
                    coin= this.allCoins.filter(a => a.marketType.includes('TOMAN'))
                    return coin
            },
    },
}
</script>

<style lang="scss" scoped>
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .coin-label{
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        align-items: center;
        img{
            width: 40px;
            height: 40px;
        }
        .changes{
            display: flex;
            flex-direction: column;
        }
    }
}
.hooper-container{
    width: 90%;
    
    .hooper-list{
        background: unset !important;
    }
}
.tomany{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
}
.coin-img{
    max-width: 40px;
    max-height: 40px;
}
.lasttrades{
    z-index: 3;
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.icon{
    width: 20%;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    align-items: center;
}
@media only screen and(max-width:1000px){
    .lasttrades{
        flex-wrap: wrap;
    }
}
</style>