<template>
    <div class="banner">
        <div class="contents">
            <div class="texts">
                <h1> بازار معاملات رمز ارز ها </h1>
                <h4> رمزارز های خود را به آسانی در صرافی امن معامله کنید </h4>
            </div>
            <div class="btn">
                <button @click.prevent="$router.push('/advancetrade')" class="submit"> پیوستن به بازار </button>
            </div>
            <div class="coins">
                <img  src="../../assets/icons/24 Market analysis.svg" alt="">
                <span> بازار های ما </span>
                <img src="../../assets/Marketcoins.svg" alt="">
            </div>
        </div>
        <img class="banner-img" :src="require('../../assets/banner.svg')" alt="" >
        <img class="cell cell1" src="../../assets/bubble/cells.svg" alt="">
        <img class="cell cell2" src="../../assets/bubble/cells.svg" alt="">
        <img class="cell cell3" src="../../assets/bubble/cells.svg" alt="">
        <img class="cell cell4" src="../../assets/bubble/cells.svg" alt="">
        <img class="cell cell5" src="../../assets/bubble/cells.svg" alt="">
    </div>
</template>
<script setup>
export default {
    name:'BannerHomePage',
}
</script>

<style lang="scss" scoped>
.cell{
    position: absolute;
}
.cell1{
    z-index: 4;
    left: -40px;
    top: 100px;
    width: 20px;
}
.cell2{
    z-index: 4;
    left: -40px;
    bottom: 140px;
}
.cell3{
    z-index: 4;
    left: 250px;
    bottom: 80px;
    width: 20px;
}
.cell4{
    z-index: 4;
    left: 600px;
    bottom: 300px;
}
.cell5{
    left: 400px;
    top: 70px;
    width: 20px;
}
.banner{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &::before{
        content: ' ';
        width: 50%;
        height: 80%;
        position: absolute;
        top: 0;
        left: -100px;
        z-index: 1;
        background: url('../../assets/bubble/Polygon.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .banner-img{
        z-index: 3;
        width: 40%;
        height: 100%;
    }
    .contents{
        min-height: 350px;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 3;
        .texts{
            h1{
                font-family: 'sepahbod' !important;
                color: var(--purple-title);
                font-size: clamp(28px,2vw,36px);
            }
            h4{
                word-spacing: 3px;
                color: var(--dark-grey);
                font-size: clamp(12px,2vw,14px);
            }
            display: flex;
            flex-direction: column;
            row-gap: 16px;
        }
        .btn{
            max-width: 180px;
        }
        .coins{
            span{
                color: var(--purple-title);
            }
            gap: 16px;
            flex-wrap: wrap;
            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: space-between;
        }
    }
}
@media only screen and(max-width:1100px){
    .cell{
        display: none;
    }
    .banner{
        flex-direction: column;
        padding: 30px 20px;
        &::before{
            content: none !important;
        }
        .banner-img{
            width: 100%;
        }
        .contents{
            width: 100%;
            align-items: center;
        }
        .btn{
            width: 100%;
            max-width: 250px !important;
        }
        .coins{
            width: 80%;
        }
    }
}
</style>