<template>
    <div class="market">
        <div class="title">
            <img src="../../assets/icons/market-research.svg" alt="">
            <span> نگاه کلی به بازار ها </span>
        </div>

        <div class="tablecontainer">
            <table>
                <tr cellspacing="0" class="header">
                    <td style="width:9%">
                        <div class="priceTypeBtns">
                            <div class="pricebtns">
                                <button @click="changeMarket(true)" :class="{'focus':toman}" class="marketClick"> تومان </button>
                                <button @click="changeMarket(false)" :class="{'focus':!toman}" class="marketClick"> تتر </button>
                                <div :class="toman ? 'toman':'tether'" class="underLineHover"></div>
                            </div>
                        </div> 
                    </td>
                    <td>
                        <div class="td-container">
                            <span> بازار </span>
                        </div>
                    </td>
                    <td>
                        <div class="td-container">
                            <span> آخرین قیمت  </span>
                            <div class="filters">
                                <img  @click="changeFilter('highestLastPrice','up')"  :class="{'focus':highestLastPrice=='up'}" class="remove3" src="../../assets/icons/sort.svg" alt="">
                                <img @click="changeFilter('highestLastPrice','down')" :class="{'focus':highestLastPrice=='down'}" class="remove3" style="transform: rotate(180deg);" src="../../assets/icons/sort.svg" alt="">
                            </div>
                        </div>
                    </td>
                    <td class="remove">
                        <div class="td-container">
                            <span> حجم معاملات </span>
                            <div class="filters">
                                <img @click="changeFilter('highestVolume','up')" :class="{'focus':highestVolume=='up'}" src="../../assets/icons/sort.svg" alt="">
                                <img  @click="changeFilter('highestVolume','down')" :class="{'focus':highestVolume=='down'}" style="transform: rotate(180deg);" src="../../assets/icons/sort.svg" alt="">
                            </div>
                        </div>
                    </td>
                    <td>
                        <div style="justify-content:center" class="td-container">
                            <span> تغییرات </span>
                            <div class="filters">
                                <img @click="changeFilter('highestChanges','up')" :class="{'focus':highestChanges=='up'}" class="remove3" src="../../assets/icons/sort.svg" alt="">
                                <img @click="changeFilter('highestChanges','down')" :class="{'focus':highestChanges=='down'}" class="remove3" style="transform: rotate(180deg);" src="../../assets/icons/sort.svg" alt="">
                            </div>
                        </div>
                    </td>
                    <td class="remove3">
                        <div class="priceTypeBtns">
                            <button style="border-right:none" @click="changRange='daily'" :class="{'focus':changRange=='daily'}" class="marketClick changesbtn"> 24H </button>
                            <button @click="changRange='weekly'" :class="{'focus':changRange=='weekly'}" class="marketClick changesbtn"> 7D </button>
                            <button @click="changRange='monthly'" :class="{'focus':changRange=='monthly'}" class="marketClick changesbtn"> 1M </button>
                            <button @click="changRange='yearly'" :class="{'focus':changRange=='yearly'}" class="marketClick changesbtn"> 1y </button>
                            <!-- <div :class="[{'daily':changRange=='daily'},{'weekly':changRange=='weekly'},{'monthly':changRange=='monthly'},{'yearly':changRange=='yearly'}]" class="underLineChange"></div> -->
                        </div> 
                    </td>
                </tr>
                <tr class="selecting-coin" @click="reD(item.relatedCoin)" v-for="(item,index) in filteredMarket" :key="index">
                    <td style="text-align:center"> {{index+1}} </td>
                    <td> 
                        <div class="coin">
                            <img class="coin-img remove3" :src="'/coins/'+$coin2Snake[item.relatedCoin]+'.png'" alt="">
                            <span class="remove2"> {{$coinLabel[item.relatedCoin]}} </span>
                            <span> {{$coinUnit[item.relatedCoin]}} </span>
                        </div>    
                    </td>
                    <td> {{$toLocal(item.lastPrice,2)}} </td>
                    <td class="remove"> {{$toLocal(item.last24HourVolume,$decimal[$coin2Snake[item.relatedCoin]])}} </td>
                    <td>
                        <div class="chart-container">
                            <span v-if="item.last24HourChange>0" class="success"> {{$toLocal(item.last24HourChange,2)}} %+ </span>
                            <span v-if="item.last24HourChange<=0" class="fail"> {{ $toLocal(Math.abs(item.last24HourChange),2) }} %- </span>
                            <TrendChart class="remove2" :data="item.lastWeekData" />
                        </div>
                    </td>
                    <td class="remove3">
                        <div class="btn">
                            <button class="submit"> معامله </button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import TrendChart from '../Tools/TrendChart.vue'
export default {
    name:'MarketTable',
    props:['allCoins'],
    components:{
        TrendChart,
    },
    data(){
        return{
            changRange:'weekly',
            toman:false,
            highestLastPrice:null,
            highestChanges:null,
            highestVolume:null,
            market:[],
            series:[{
                name: "series-1",
                data: [30, 40, 35, 50, 49, 60, 70, 91],
            }],
            chartOptions:{
                chart: {
                    id: "vuechart-example",
                    },
                    xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                    },
            },
        }
    },
    computed:{
        filteredMarket(){
            let data=this.allCoins
                if(this.toman){
                    data=data.filter(a => a.marketType.includes('TOMAN'))
                }
                if(!this.toman){
                    data=data.filter(a => a.relatedCoin !== 'tether')
                    data=data.filter(a => a.marketType.includes('TETHER'))
                }
                
                if(this.highestLastPrice=='up'){
                    data=data.sort((a,b) => b.lastPrice - a.lastPrice)
                }
                
                if(this.highestLastPrice=='down'){
                    data=data.sort((a,b) => a.lastPrice - b.lastPrice)
                }
                
                if(this.highestChanges=='up'){
                    data=data.sort((a,b) => b.last24HourChange - a.last24HourChange)
                }
                
                if(this.highestChanges=='down'){
                    data=data.sort((a,b) => a.last24HourChange - b.last24HourChange)
                }
                
                if(this.highestVolume=='up'){
                    data=data.sort((a,b) => b.last24HourVolume - a.last24HourVolume)
                }
                
                if(this.highestVolume=='down'){
                    data=data.sort((a,b) => a.last24HourVolume - b.last24HourVolume)
                }
            return data
        },
    },
    methods:{
            reD(relatedCoin){
                this.$router.push('/advancetrade/'+(this.toman ? 'TOMAN':'TETHER')+ '/' + this.$coin2Snake[relatedCoin])
            },
            changeFilter(filter,value){
                this.highestChanges=null
                this.highestLastPrice=null
                this.highestVolume=null
                this[filter]=value;

            },
            changeMarket(priceType){
                this.toman=priceType;
                this.highestLastPrice='';
                this.highestChanges='';
                this.highestVolume='';

            },
    },
}
</script>

<style lang="scss" scoped>
.selecting-coin:hover{

    cursor: pointer;
    background: var(--light-yellow);
}
.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.submit{
    max-width: 103px;
}
.chart-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    column-gap: 24px;
}
.coin{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    .coin-img{
        max-width: 40px;
        max-height: 40px;
}
}
.underLineHover{
    padding: 0px;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    position: absolute;
    width: 50%;
    max-width: 25px;
    bottom:-5px;
    transition: 500ms;
}
.underLineChange{
    padding: 0px;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    position: absolute;
    width: 50%;
    max-width: 20px;
    bottom:-5px;
    transition: 500ms;
}
.daily{
    right: 8px;
}
.weekly{
    right: 12.5%;
}
.monthly{
    right: 23%;
}
.yearly{
    right: 33.5%;
}
.toman{
    right: 20px;
    // left: unset;
}
.tether{
    // left: 0;
    right: 60%;
}
.marketClick{
    color: var(--dark-grey);
    opacity: .4;
    font-size: clamp(12px,1vw,14px);
    transition: 500ms;
}
table{
    width: 100%;
    border-spacing: 0 !important;
    tr{
        height: 60px;
    }
}
.header{
    border-collapse: collapse;
    height: 50px;
    color: var(--dark-grey);
    td{
        // padding: 10px 0 10px 0 !important;
        padding: 0 !important;
    }
}
.focus{
    opacity: 1 !important;
}
.td-container{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
.filters{
    img{
        cursor: pointer;
        opacity: .4;
        transition: 300ms;
    }
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.tablecontainer{
    background: var(--table-background);
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
}
.priceTypeBtns{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    column-gap: 30px;
    .pricebtns{
        position: relative;
        padding: 0px 10px;
        display: flex;
        flex-direction: row;
    }
    .changesbtn{
        font-family: 'Yekan-en' !important;
        border-right: 1px solid var(--grey);
    }
}
.market{
    z-index: 3;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 200px;
    row-gap: 40px;
}
.header{
    background: var(--table-header);
}
.title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
}
</style>