<template>
    <div class="home-container">
        <div class="homepage">
            <Banner />
            <LastTrades :allCoins="coins" />
            <Market :allCoins="coins" />
            <WhyUs />
        </div>
        <Carusel class="carusel" />
    </div>
</template>
<script>
import Banner from "./Banner.vue";
import LastTrades from './LastTrades.vue';
import Market from './Market.vue';
import WhyUs from './WhyUs.vue';
import Carusel from './Carusel.vue';


export default {
    name:'HomePage',
    components:{
        Banner,
        LastTrades,
        Market,
        WhyUs,
        Carusel,
    },
    data(){
        return{
            coins:[],
        }
    },
    methods:{
        async setData() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info?weeklyDataIncluded=true')
                    this.coins= res.content
            },
    },
    mounted() {
        this.setData();
    },
}




</script>
<style lang="scss" scoped>
.carusel{
    padding:0 100px;
}
.homepage{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--home-background);
    padding:0 100px;
    overflow: hidden;
    position: relative;
    &::before{
        content: ' ';
        position: absolute;
        left: 0;
        top: 1970px;
        width: 612px;
        height: 612px;
        background: url('../../assets/bubble/Polygon3.png');
        background-position: left;
        background-size: 85%;
        background-repeat: no-repeat;
    }
    &::after{
        content: ' ';
        position: absolute;
        right: 0;
        top: 500px;
        width: 712px;
        height: 712px;
        background: url('../../assets/bubble/Polygon2.png');
        background-position: right;
        background-size: 65%;
        background-repeat: no-repeat;
    }
}
@media only screen and(max-width:1100px){
    .homepage{
        padding: 30px 20px;
        &::before{
            content: none !important;
        }
        &::after{
            content: none !important;
        }
    }
    .carusel{
        padding: 30px 20px;
    }
}
</style>