<template>
      <div class="carousel">
        <Hooper :settings="hoopersSetting">
            <Slide v-for="(item,index) in blogs" :key="index" class="hooper-item">
              
              <img class="slide-style" v-if="item" :src=item.better_featured_image.source_url alt="">
              <h1 v-else> fuck </h1>

            </Slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </Hooper>
      </div>
</template>

<script>
import {Hooper, Slide,Navigation as HooperNavigation} from 'hooper'
import 'hooper/dist/hooper.css';
export default {
  name: 'CryptoCarousel',
  props:['content'],
    data() {
      return {
        hoopersSetting:{

        rtl:true,
        infiniteScroll:true,
        autoPlay:false,
        autoplaySpeed: 2000,
        itemsToShow: 4,
        centerMode:false,
        breakpoints : {
          1200:{
            itemsToShow: 4,
            centerMode:true,
          },
          800:{
            itemsToShow: 3,
            centerMode:true,
          },
          500:{
            itemsToShow:2,

          },
          320:{
            itemsToShow:1,
            centerMode:true,
          }
        }
      },
      }
    },
    computed:{
      blogs(){
        let blog
          if(this.content){
            blog=this.content
          }
          else{
            blog = []
          }
        return blog
      }
    },
    components: {
      Hooper,
      Slide,
      HooperNavigation
  },
  mounted() {
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.slide-style{
  width: 100%;
}
.content{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    width: 100%;
    background: var(--table-background);
    padding: 10px;
    border-bottom-left-radius: 15px;
    align-items: center;
    border-bottom-right-radius: 15px;
    .point{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.carusel-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    background: var(--light-blue);
}
.slide{
    max-width: 336px;
    display: flex;
    flex-direction: column;
}
.vueperslide__content-wrapper{
   background: var(--crypto-carousel-bg);
  
}
.card{
  height: 100%;
   padding: 16px;
   display: flex;
   width: 100%;
   flex-direction: column;
   align-items: center;
  
   align-content: flex-start;

}

.chart{
  max-width: 176px;
}
.carousel{
  height: 70%;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  &:first-child{
      height: 300px !important;
  }
}
.vueperslide__title{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.crypto-title{
  font-size: clamp(10px,1.2vw,12px);
  color:var(--crypto-carousel-title)
}
.crypto-change{
  font-size: clamp(10px,1.2vw,12px);
  color: var(--crypto-carousel-change);
  font-weight: bold;
}
.crypto-symbol{
  font-weight: bold;
  font-size: clamp(14px,1.6vw,16px);
  color:var(--crypto-carousel-symbol)
}

.price{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: clamp(10px,1.2vw,12px);
  color:var(--crypto-carousel-price)

}
</style>